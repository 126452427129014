import React, {useState, useEffect, useCallback} from 'react';
import { ReactLenis,useLenis  } from '@studio-freight/react-lenis';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowRight, Mail, Phone, MapPin, Send, Network, TrendingUp, PlayCircle, Swords, User, Loader2, Star, MessageCircle, ThumbsUp, BadgeCheck, Quote, Zap, Cpu, Puzzle, Scale, Languages, Volume2, Rocket, ArrowLeft,X, Headphones, Calendar, ChartBar, Check, PhoneCall, Shield, Clock, BarChart as ChartIcon, Users, MessageSquare, Settings, Facebook, Twitter, Instagram, Linkedin } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, FunnelChart, Funnel, LabelList  } from 'recharts';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import axios from 'axios';
import toast from 'react-hot-toast';


import Bolofy from '../assets/Picture1.jpg';
import DPIIT from '../assets/Picture2.png';
import Build from '../assets/build.jpg';
import tam from '../assets/tam.png';
import demo1 from '../assets/Picture3.png';
import demo2 from '../assets/Picture5.png';
import demo3 from '../assets/Picture6.png';
import demo4 from '../assets/Picture7.png';


// Validation patterns
const validationRegex = {
  name: /^[A-Za-z\s]{2,50}$/,
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
};

const testimonials = [
  {
    id: 1,
    content: "Bolofy's AI voice bot has transformed our customer service. We've seen a 75% reduction in response time and 90% customer satisfaction!",
    author: "Rajesh Kumar",
    position: "CEO",
    company: "TechSmart Solutions",
    image: "https://res.cloudinary.com/dkyrtfk1u/image/upload/v1697349373/sli9ffed1vdqegf1srip.jpg",
    rating: 5,
    stats: {
      efficiency: "95%",
      satisfaction: "98%"
    }
  },
  {
    id: 2,
    content: "The natural language processing is incredible. Our customers can't tell they're talking to an AI. It's been a game-changer for our business.",
    author: "Priya Sharma",
    position: "Operations Head",
    company: "CloudNet Services",
    image: "https://res.cloudinary.com/dkyrtfk1u/image/upload/v1697349373/sli9ffed1vdqegf1srip.jpg",
    rating: 4,
    stats: {
      efficiency: "92%",
      satisfaction: "96%"
    }
  },
  {
    id: 3,
    content: "We've reduced missed calls by 95% and automated appointment booking. The ROI is incredible - it paid for itself in the first month!",
    author: "Amit Patel",
    position: "Director",
    company: "GlobalTech India",
    image: "https://res.cloudinary.com/dkyrtfk1u/image/upload/v1697349373/sli9ffed1vdqegf1srip.jpg",
    rating: 4,
    stats: {
      efficiency: "97%",
      satisfaction: "94%"
    }
  }
];

const featuresWhy = [
  {
    icon: Zap,
    title: "Zero downtime",
    description: "Our bot can handle multiple callers on different channels at the same time without breaking a sweat.",
    color: "blue"
  },
  {
    icon: Cpu,
    title: "Top-notch tech",
    description: "Built to handle calls at scale, no matter the load.",
    color: "indigo"
  },
  {
    icon: Puzzle,
    title: "Easy integrations",
    description: "Works seamlessly with open-source models like GPT, Gemini, Mistral, and even local ones like Phi-3 and Llama 3.",
    color: "purple"
  },
  {
    icon: Scale,
    title: "Super flexible",
    description: "Scales easily for any industry.",
    color: "green"
  },
  {
    icon: Languages,
    title: "Language pro",
    description: "Supports 7+ Indian languages with an auto-adaptive algorithm that adjusts to the speaker's language.",
    color: "yellow"
  },
  {
    icon: Volume2,
    title: "Free voice bot",
    description: "Get our voice bot for free—just allow a quick voice ad during calls (with your consent, of course).",
    color: "pink"
  }
  // {
  //   icon: Rocket,
  //   title: "Future-ready",
  //   description: "Soon, our chatbot will manage an SME's entire workflow, HR, and customer queries. Perfect for D2C businesses!",
  //   color: "orange"
  // }
];

const containerWhyVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const itemVariants = {
  hidden: { 
    opacity: 0,
    y: 20
  },
  visible: { 
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5
    }
  }
};

const pricingData = [
  {
    name: 'Free',
    price: '$0',
    popular: false,
    features: [
      { name: 'Max 5 minute duration / call', included: true },
      { name: '300 minutes', included: true },
      { name: '24/7 support', included: false },
      { name: 'Ad-free', included: false },
      { name: 'Basic Analytics', included: true },
      { name: 'General Q and A bot without knowledgebase', included: true },
      { name: 'Pay only onboarding fees', included: true },
    ],
  },
  {
    name: 'Essential',
    price: '$60',
    popular: true,
    features: [
      { name: 'No restriction on time duration per call', included: true },
      { name: '600 minutes', included: true },
      { name: '24/7 support', included: true },
      { name: 'Ad-free', included: true },
      { name: 'Basic Analytics', included: true },
      { name: 'General Q and A bot without knowledgebase', included: true },
      { name: 'Onboarding fees are included', included: true },
    ],
  },
  {
    name: 'Premium',
    price: '$100',
    popular: false,
    features: [
      { name: 'No restriction on time duration per call', included: true },
      { name: '2000 minutes', included: true },
      { name: '24/7 support', included: true },
      { name: 'Ad-free', included: true },
      { name: 'Advance analytics', included: true },
      { name: 'Advance bot with knowledgebase', included: true },
      { name: 'Onboarding fees are included', included: true },
    ],
  },
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const cardPriceVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.5
    }
  }
};

const solutions = [
    {
      title: 'One-click AI Receptionist',
      description: 'Instantly set up your AI receptionist',
      image: demo1,
      icon: PhoneCall,
      color: 'blue'
    },
    {
      title: 'Call Log Dashboard',
      description: 'Track and analyze all your communications',
      image: demo2,
      icon: ChartIcon,
      color: 'indigo'
    },
    {
      title: 'Quick Chatbot Integration',
      description: 'Embed chatbots with a single click',
      image: demo3,
      icon: MessageSquare,
      color: 'purple'
    },
    {
      title: 'Knowledge Base Generator',
      description: 'Auto-generate chat knowledge base',
      image: demo4,
      icon: Clock,
      color: 'cyan'
    }
  ];

  const features = [
    {
      title: 'Intelligent Call Handling',
      description: 'Advanced AI system that manages calls 24/7',
      icon: PhoneCall,
    },
    {
      title: 'Smart Information Delivery',
      description: 'Automated responses to common queries',
      icon: MessageSquare,
    },
    {
      title: 'Appointment Scheduling',
      description: 'Seamless booking system integration',
      icon: Clock,
    },
    {
      title: 'Analytics Dashboard',
      description: 'Comprehensive insights and reporting',
      icon: ChartIcon,
    }
  ];

const timelineData = [
    {
      number: "1",
      age: "Stone Age (0 to 6 months)",
      description: "Initial product development and testing. Pilot launches and early customer acquisition"
    },
    // {
    //   number: "2",
    //   age: "First 6 Months",
    //   description: "Pilot launches and early customer acquisition"
    // },
    {
      number: "2",
      age: "Bronze Age",
      description: "Expansion of features and customer base growth"
    },
    {
      number: "3",
      age: "Golden Age",
      description: "Full-scale market penetration and expansion"
    }
  ];
  
const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
};

const LandingPage = () => {
  const currentYear = new Date().getFullYear();
  const [activeIndex, setActiveIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
// Add this useEffect for auto-changing images
useEffect(() => {
  if (isHovered) return;
  
  const timer = setInterval(() => {
    setActiveImage((prev) => (prev + 1) % solutions.length);
  }, 3000); // Changes every 5 seconds

  return () => clearInterval(timer);
}, [isHovered]);


  useEffect(() => {
    if (isHovered) return;
    
    const timer = setInterval(() => {
      setDirection(1);
      setActiveIndex((prev) => (prev + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(timer);
  }, [isHovered]);

  const handleNext = () => {
    setDirection(1);
    setActiveIndex((prev) => (prev + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setDirection(-1);
    setActiveIndex((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };



  // Form states
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showToast, setShowToast] = useState({ show: false, type: '', message: '' });

  // Field validation
  const validateField = useCallback((name, value) => {
    let error = '';
    switch (name) {
      case 'name':
        if (!value.trim()) {
          error = 'Name is required';
        } else if (!validationRegex.name.test(value)) {
          error = 'Name should be 2-50 characters long and contain only letters and spaces.';
        }
        break;
      case 'email':
        if (!value) {
          error = 'Email is required';
        } else if (!validationRegex.email.test(value)) {
          error = 'Please enter a valid email address.';
        }
        break;
      case 'phone':
        if (!value) {
          error = 'Phone number is required';
        } else if (!isPossiblePhoneNumber(value)) {
          error = 'Please enter a valid phone number.';
        }
        break;
      default:
        break;
    }
    return error;
  }, []);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  // Handle phone input change
  const handlePhoneChange = (value) => {
    setFormData(prev => ({
      ...prev,
      phone: value
    }));
    
    if (errors.phone) {
      setErrors(prev => ({
        ...prev,
        phone: ''
      }));
    }
  };

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate all fields
    const newErrors = {
      name: validateField('name', formData.name),
      email: validateField('email', formData.email),
      phone: formData.phone ? validateField('phone', formData.phone) : '',
    };
  
    if (Object.values(newErrors).some(error => error !== '')) {
      setErrors(newErrors);
      toast.error('Please correct the errors in the form.', {
        duration: 3000,
        style: {
          background: '#FF4B4B',
          color: '#fff',
          borderRadius: '10px',
        },
      });
      return;
    }
  
    if (submitted) {
      toast.error('You have already submitted the form.', {
        duration: 3000,
        style: {
          background: '#FF4B4B',
          color: '#fff',
          borderRadius: '10px',
        },
      });
      return;
    }
  
    setIsSubmitting(true);
  
    // Create loading toast
    const loadingToast = toast.loading('Sending message...', {
      style: {
        background: '#1E293B',
        color: '#fff',
        borderRadius: '10px',
      },
    });
  
    try {
      const data = {
        name: formData.name,
        email: formData.email,
        mobile: formData.phone || null,
        chatBotId: 1, // Replace with your chatbot ID
      };
  
      const response = await axios.post(
        'https://bolofy.com/api/botCallLog/bot-lead-capture',
        data,
        {
          headers: {
            'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ikpva`,
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response.status === 200) {
        // Dismiss loading toast and show success
        toast.dismiss(loadingToast);
        toast.success('Message sent successfully!', {
          duration: 3000,
          style: {
            background: '#10B981',
            color: '#fff',
            borderRadius: '10px',
          },
        });
  
        setFormData({ name: '', email: '', phone: '' });
        setErrors({});
        setSubmitted(true);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      let errorMessage = 'Failed to send message. Please try again.';
      
      if (error.response) {
        errorMessage = error.response.data.message || errorMessage;
      } else if (error.request) {
        errorMessage = 'No response received from server.';
      }
  
      // Dismiss loading toast and show error
      toast.dismiss(loadingToast);
      toast.error(errorMessage, {
        duration: 3000,
        style: {
          background: '#FF4B4B',
          color: '#fff',
          borderRadius: '10px',
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  

  // CSS Classes
  const inputClasses = "w-full bg-white/10 rounded-xl px-5 py-3 text-white placeholder:text-gray-400 border-2 focus:outline-none transition-all duration-300";
  const labelClasses = "text-white/80 text-sm font-medium  ml-1";
  // const [isVisible, setIsVisible] = useState(false);
  // const lenis = useLenis();

  // useEffect(() => {
  //   const toggleVisibility = () => {
  //     if (window.scrollY > 300) {
  //       setIsVisible(true);
  //     } else {
  //       setIsVisible(false);
  //     }
  //   };

  //   window.addEventListener('scroll', toggleVisibility);
  //   return () => window.removeEventListener('scroll', toggleVisibility);
  // }, []);

  // const scrollToTop = () => {
  //   lenis.scrollTo(0, { 
  //     duration: 1.2,
  //     easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t))
  //   });
  // };

    const [activeImage, setActiveImage] = useState(0);
 

  return (
    <div className="min-h-screen bg-white">
    {/* Hero Section */}
    <section className="relative min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-900 via-blue-900 to-blue-800 overflow-hidden">
      {/* Background Circles for Design */}
      <motion.div 
        className="absolute top-0 left-0 w-[800px] h-[800px] bg-indigo-400 rounded-full filter blur-3xl opacity-10"
        animate={{
          x: [-200, -100],
          y: [-200, -150],
          scale: [1, 1.1, 1],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          repeatType: "reverse"
        }}
      />
      <motion.div 
        className="absolute bottom-0 right-0 w-[600px] h-[600px] bg-sky-400 rounded-full filter blur-3xl opacity-10"
        animate={{
          x: [100, 200],
          y: [100, 150],
          scale: [1, 1.2, 1],
        }}
        transition={{
          duration: 10,
          repeat: Infinity,
          repeatType: "reverse"
        }}
      />
      
      {/* Main Content Container */}
      <div className="container mx-auto px-4 md:px-8 xl:px-16 max-w-[1920px] relative z-10">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          {/* Left Content */}
          <motion.div 
            className="text-white space-y-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            {/* Logo & Recognition */}
            <div className="flex flex-col space-y-6">
            <h1 className="text-8xl font-bold leading-tight bg-gradient-to-br from-slate-50 to-slate-400 text-transparent bg-clip-text">
            Bolofy
            </h1>
            <h1 className="text-4xl font-bold leading-tight">
                Wave of Voice AI
                {/* <span className="bg-gradient-to-r from-sky-200 to-indigo-200 text-transparent bg-clip-text"> I</span> */}
                <span className="font-medium block text-2xl text-sky-100">
                  AI-powered Receptionist & Web Chatbots
                </span>
              </h1>
              {/* Logo Container with Better Visibility */}
           
              
              {/* Update the recognition badges section */}
              <div className="flex flex-col py-5 space-y-6 md:flex-row md:space-x-6 md:space-y-0 md:items-start">
  {/* DPIIT Recognition */}
  <div className="flex flex-row  items-center space-x-4">
    <div className="bg-white p-2 rounded-xl">
      <img src={DPIIT} alt="DPIIT Recognition" className="h-12" />
    </div>
    <span className="text-sm font-medium bg-white/10 backdrop-blur-md px-6 py-3 rounded-full border border-white/20">
      Recognized by DPIIT
    </span>
  </div>

  {/* Build3 Recognition */}
  <div className="flex flex-row items-center space-x-4">
    <div className="bg-white p-2 rounded-xl w-20 ">
      <img src={Build} alt="Winner of Build3 cohort 2" className="h-12 mr-2 w-14 object-cover"  />
    </div>
    <span className="text-xs md:text-sm font-medium text-wrap bg-white/10 backdrop-blur-md px-6 py-3 rounded-full border border-white/20">
      Winner - Build3 accelerator, Goa (cohort 2)
    </span>
  </div>
</div>




            </div>
              
           

            {/* Main Text Content */}
            <div className="space-y-6">
           
              
              {/* Contact Info with Enhanced Design */}
              <div className="rounded-xl md:rounded-2xl">
  <div className="flex flex-col space-y-4">
    <div className="flex items-center space-x-3">
      <div className="p-3 bg-sky-400/20 rounded-xl group-hover:bg-sky-400/30 transition-colors">
        <Mail className="w-5 h-5 text-sky-200" />
      </div>
      <a className="text-lg font-medium" href="mailto:sp@bolofy.com">sp@bolofy.com</a>
    </div>
    <div className="flex items-center space-x-3">
      <div className="p-3 bg-sky-400/20 rounded-xl group-hover:bg-sky-400/30 transition-colors">
        <Phone className="w-5 h-5 text-sky-200" />
      </div>
      <a className="text-lg font-medium" href="tel:+9035163809">9035163809</a>
    </div>
  </div>
</div>

            </div>

  
            
          </motion.div>

         {/* Right Content - Glassmorphic Card */}
<motion.div
  className="lg:block" // Removed hidden to show on mobile
  initial={{ opacity: 0, x: 50 }}
  animate={{ opacity: 1, x: 0 }}
  transition={{ duration: 0.8 }}
>
  <div className="bg-gradient-to-br from-white/20 to-white/5 backdrop-blur-md rounded-3xl mb-2 p-4 md:p-8 shadow-2xl border border-white/20">
    {/* Responsive Video Container */}
    <div className="relative w-full aspect-video overflow-hidden rounded-xl">
      <iframe 
        className="absolute top-0 left-0 w-full h-full"
        src="https://www.youtube.com/embed/B5SFoYwASYY" 
        title="Appointment booking using voice bot" 
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        allowFullScreen
      />
    </div>
    
    <div className="mt-8 space-y-5">
      {[
        { icon: Headphones, text: "24/7 Automated Call Handling" },
        { icon: Calendar, text: "Smart Appointment Scheduling" },
        { icon: ChartBar, text: "Real-time Analytics Dashboard" }
      ].map((item, index) => (
        <motion.div 
          key={index}
          className="flex items-center space-x-4 text-white group"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: index * 0.2 }}
        >
          <div className="p-3 bg-sky-400/20 rounded-xl group-hover:bg-sky-400/30 transition-colors">
            <item.icon className="w-6 h-6 text-sky-200" />
          </div>
          <span className="text-lg">{item.text}</span>
        </motion.div>
      ))}
    </div>
  </div>
</motion.div>
        </div>
      </div>

      {/* Enhanced Bottom Gradient */}
      <div className="absolute bottom-0 left-0 right-0 h-40 bg-gradient-to-t from-indigo-900/50 via-blue-900/30 to-transparent"></div>
    </section>

      {/* Enhanced Business Challenges Section */}
      <section className="py-20 bg-gradient-to-b from-gray-50 to-white relative overflow-hidden">
        {/* Background Decorative Elements */}
        <div className="absolute inset-0 bg-grid-pattern opacity-5"></div>
        
        <div className="container mx-auto px-4 md:px-8 xl:px-16 relative z-10">
          <motion.div
            initial={fadeIn.initial}
            whileInView={fadeIn.animate}
            transition={fadeIn.transition}
            className="text-center max-w-7xl mx-auto"
          >
            <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-violet-600 to-indigo-600 text-transparent bg-clip-text">A Typical Day in a Business Life</h2>
            <p className="text-gray-600 mb-16 text-lg max-w-3xl mx-auto">
              Experience the daily challenges that modern businesses face and discover how we can help overcome them.
            </p>
            
            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  title: 'Repetitive Calls',
                  description: 'Hours spent answering the same questions, reducing productivity and focus on core business tasks',
                  icon: PhoneCall,
                  color: 'blue'
                },
                {
                  title: 'Spam Calls',
                  description: 'Constant interruptions from unwanted calls leading to frustration and lost productive hours',
                  icon: Shield,
                  color: 'red'
                },
                {
                  title: 'Lost Opportunities',
                  description: 'Missing important calls during busy hours resulting in potential revenue loss',
                  icon: Clock,
                  color: 'green'
                }
              ].map((challenge, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                  whileHover={{ y: -5, transition: { duration: 0.2 } }}
                  className="relative bg-white rounded-2xl shadow-xl p-8 group hover:shadow-2xl transition-all duration-300"
                >
                  {/* Icon Container */}
                  <div className={`
                    w-16 h-16 mx-auto mb-6 rounded-2xl flex items-center justify-center
                    ${challenge.color === 'blue' && 'bg-blue-100 text-blue-600'}
                    ${challenge.color === 'red' && 'bg-red-100 text-red-600'}
                    ${challenge.color === 'green' && 'bg-green-100 text-green-600'}
                    transform group-hover:scale-110 transition-transform duration-300
                  `}>
                    <challenge.icon className="w-8 h-8" />
                  </div>

                  {/* Content */}
                  <h3 className="text-xl font-bold mb-4 text-gray-800">
                    {challenge.title}
                  </h3>
                  <p className="text-gray-600 leading-relaxed">
                    {challenge.description}
                  </p>

                  {/* Decorative Element */}
                  <div className={`
                    absolute bottom-0 left-0 right-0 h-1 rounded-b-full transform scale-x-0 group-hover:scale-x-95 transition-transform duration-300
                    ${challenge.color === 'blue' && 'bg-blue-500'}
                    ${challenge.color === 'red' && 'bg-red-500'}
                    ${challenge.color === 'green' && 'bg-green-500'}
                  `}></div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </section>


       {/* Enhanced Problem Statement Section */}
{/* Enhanced Problem Section */}
<section className="py-20 bg-gradient-to-b from-white to-gray-50 relative overflow-hidden">
  {/* Animated Background Pattern */}
  <motion.div 
    className="absolute inset-0 bg-grid-pattern opacity-5"
    animate={{
      backgroundPosition: ["0px 0px", "100px 100px"],
      opacity: [0.05, 0.08, 0.05]
    }}
    transition={{
      duration: 20,
      repeat: Infinity,
      repeatType: "reverse"
    }}
  />

  <div className="container mx-auto px-4 md:px-8 xl:px-16 relative z-10">
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className="max-w-7xl mx-auto"
    >
      {/* Section Header */}
      <div className="text-center mb-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-4xl font-bold mb-6">
            <span className="bg-gradient-to-r from-red-500 to-orange-500 text-transparent bg-clip-text">
              The Problem
            </span>
          </h2>
          <p className="text-gray-600 text-xl max-w-3xl mx-auto">
            Businesses face critical challenges that impact their growth and customer satisfaction
          </p>
        </motion.div>
      </div>

      {/* Problem Cards Grid */}
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {[
          {
            title: '30% Business Loss',
            description: 'Potential business loss due to missed calls, causing 20% revenue loss',
            icon: ChartIcon,
            color: 'red',
            stat: '30%'
          },
          {
            title: 'Spam Disruption',
            description: 'Frustrating spam calls disrupting work and reducing productivity',
            icon: Shield,
            color: 'yellow',
            stat: '40%'
          },
          {
            title: 'High Turnover',
            description: 'High turnover rate of receptionists leading to inconsistent service',
            icon: Users,
            color: 'purple',
            stat: '25%'
          },
          {
            title: 'Limited Insights',
            description: 'Lack of customer insights for improving retention and repeat purchases',
            icon: MessageSquare,
            color: 'blue',
            stat: '35%'
          },
          {
            title: 'Missed Opportunities',
            description: 'Lost potential clients due to unavailability during peak hours',
            icon: Clock,
            color: 'green',
            stat: '45%'
          },
          {
            title: 'Customer Experience',
            description: 'Inconsistent customer service affecting business reputation',
            icon: PhoneCall,
            color: 'indigo',
            stat: '28%'
          }
        ].map((problem, index) => (
          <motion.div
            key={index}
            variants={{
              hidden: { opacity: 0, y: 20 },
              visible: { opacity: 1, y: 0 }
            }}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            whileHover={{ scale: 1.02, translateY: -5 }}
            className="bg-white rounded-2xl p-8 shadow-xl hover:shadow-2xl transition-all duration-300 relative overflow-hidden group"
          >
            {/* Decorative Background */}
            <motion.div
              className={`absolute inset-0 opacity-0 group-hover:opacity-10 transition-opacity duration-300
                ${problem.color === 'red' && 'bg-red-100'}
                ${problem.color === 'yellow' && 'bg-yellow-100'}
                ${problem.color === 'purple' && 'bg-purple-100'}
                ${problem.color === 'blue' && 'bg-blue-100'}
                ${problem.color === 'green' && 'bg-green-100'}
                ${problem.color === 'indigo' && 'bg-indigo-100'}
              `}
              initial={{ scale: 0 }}
              whileHover={{ scale: 1 }}
            />

            {/* Icon Container */}
            <motion.div
              className={`
                w-16 h-16 rounded-2xl flex items-center justify-center mb-6 relative
                ${problem.color === 'red' && 'bg-red-100 text-red-500'}
                ${problem.color === 'yellow' && 'bg-yellow-100 text-yellow-600'}
                ${problem.color === 'purple' && 'bg-purple-100 text-purple-500'}
                ${problem.color === 'blue' && 'bg-blue-100 text-blue-500'}
                ${problem.color === 'green' && 'bg-green-100 text-green-500'}
                ${problem.color === 'indigo' && 'bg-indigo-100 text-indigo-500'}
              `}
              whileHover={{ rotate: [0, -10, 10, 0] }}
              transition={{ duration: 0.5 }}
            >
              <problem.icon className="w-8 h-8" />
            </motion.div>

            {/* Content */}
            <div className="space-y-3 relative">
              <div className="flex items-center justify-between">
                <h3 className="text-xl font-bold">{problem.title}</h3>
                <motion.span 
                  className={`
                    text-2xl font-bold
                    ${problem.color === 'red' && 'text-red-500'}
                    ${problem.color === 'yellow' && 'text-yellow-600'}
                    ${problem.color === 'purple' && 'text-purple-500'}
                    ${problem.color === 'blue' && 'text-blue-500'}
                    ${problem.color === 'green' && 'text-green-500'}
                    ${problem.color === 'indigo' && 'text-indigo-500'}
                  `}
                  initial={{ scale: 0 }}
                  whileInView={{ scale: 1 }}
                  transition={{ delay: 0.3 }}
                >
                  {problem.stat}
                </motion.span>
              </div>
              <p className="text-gray-600 leading-relaxed">{problem.description}</p>
            </div>

            {/* Hover Effect Line */}
            <motion.div 
              className={`
                absolute bottom-0 left-0 h-1 bg-gradient-to-r w-full
                ${problem.color === 'red' && 'from-red-500 to-red-600'}
                ${problem.color === 'yellow' && 'from-yellow-500 to-yellow-600'}
                ${problem.color === 'purple' && 'from-purple-500 to-purple-600'}
                ${problem.color === 'blue' && 'from-blue-500 to-blue-600'}
                ${problem.color === 'green' && 'from-green-500 to-green-600'}
                ${problem.color === 'indigo' && 'from-indigo-500 to-indigo-600'}
              `}
              initial={{ scaleX: 0 }}
              whileHover={{ scaleX: 1 }}
              transition={{ duration: 0.3 }}
            />
          </motion.div>
        ))}
      </div>
    </motion.div>
  </div>
</section>

  {/* Enhanced Solution Section */}
  <section id="solution"className="py-20 bg-gradient-to-br from-blue-50 via-indigo-50 to-blue-50 relative overflow-hidden">
      {/* Animated Background Elements */}
      <motion.div 
        className="absolute top-0 left-0 w-96 h-96 bg-blue-200 rounded-full filter blur-3xl opacity-20"
        animate={{
          x: [-10, 10],
          y: [-10, 10],
          scale: [1, 1.1, 1],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          repeatType: "reverse"
        }}
      />
      <motion.div 
        className="absolute bottom-0 right-0 w-96 h-96 bg-indigo-200 rounded-full filter blur-3xl opacity-20"
        animate={{
          x: [10, -10],
          y: [10, -10],
          scale: [1, 1.1, 1],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          repeatType: "reverse"
        }}
      />

      <div className="container mx-auto px-4 md:px-8 xl:px-16 relative z-10">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
          className="max-w-7xl mx-auto"
        >
          {/* Section Header */}
          <div className="text-center mb-16">
            <motion.h2 
              className="text-4xl font-bold mb-6"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <span className="bg-gradient-to-r from-blue-600 to-indigo-600 text-transparent bg-clip-text">
                Our Solution
              </span>
            </motion.h2>
            <motion.p 
              className="text-gray-600 text-xl max-w-3xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              Comprehensive AI-powered solutions to transform your business communication
            </motion.p>
          </div>

          <div className="grid lg:grid-cols-2 gap-12 items-center">
            {/* Features List */}
            <div className="space-y-8">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  whileHover={{ scale: 1.02, translateX: 5 }}
                  className="flex items-start space-x-4 bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 group"
                >
                  <div className="bg-gradient-to-br from-blue-100 to-indigo-100 p-3 rounded-lg text-blue-600 group-hover:scale-110 transition-transform duration-300">
                    <feature.icon className="w-6 h-6" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                    <p className="text-gray-600">{feature.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>

            {/* Image Showcase */}
            <div className="relative"
  onMouseEnter={() => setIsHovered(true)}
  onMouseLeave={() => setIsHovered(false)}
>
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="bg-white p-6 rounded-2xl shadow-2xl"
  >
    {/* Image Carousel */}
    <div className="relative overflow-hidden rounded-lg aspect-video">
      <AnimatePresence mode='wait'>
        <motion.img
          key={activeImage}
          src={solutions[activeImage].image}
          alt={solutions[activeImage].title}
          className="w-full h-full object-contain"
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={{ duration: 0.3 }}
        />
      </AnimatePresence>

      {/* Navigation Buttons */}
      <div className="absolute inset-0 flex items-center justify-between p-4">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            setActiveImage((prev) => (prev === 0 ? solutions.length - 1 : prev - 1));
            setIsHovered(true);
          }}
          className="p-2 rounded-full bg-white/80 hover:bg-white shadow-lg transition-all duration-300"
        >
          <ArrowLeft className="w-5 h-5 text-gray-800" />
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            setActiveImage((prev) => (prev === solutions.length - 1 ? 0 : prev + 1));
            setIsHovered(true);
          }}
          className="p-2 rounded-full bg-white/80 hover:bg-white shadow-lg transition-all duration-300"
        >
          <ArrowRight className="w-5 h-5 text-gray-800" />
        </motion.button>
      </div>
    </div>

    {/* Solution Title and Description */}
    <motion.div
      key={activeImage}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="text-center mt-6"
    >
      <h3 className="text-xl font-bold text-gray-800">
        {solutions[activeImage].title}
      </h3>
      <p className="text-gray-600 mt-2">
        {solutions[activeImage].description}
      </p>
    </motion.div>

    {/* Image Navigation Dots */}
    <div className="flex justify-center space-x-2 mt-4">
      {solutions.map((_, index) => (
        <motion.button
          key={index}
          onClick={() => {
            setActiveImage(index);
            setIsHovered(true);
          }}
          className={`w-2 h-2 rounded-full transition-all duration-300 ${
            activeImage === index 
              ? 'bg-blue-600 w-6' 
              : 'bg-gray-300 hover:bg-gray-400'
          }`}
          whileHover={{ scale: 1.2 }}
        />
      ))}
    </div>
  </motion.div>

              {/* "Powered by AI" Badge */}
              {/* <motion.div 
                className="absolute -bottom-6 -right-8 md:left-56 transform -translate-x-1/2"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
              >
                <div className="bg-gradient-to-r w-52 text-[0.8rem] from-blue-600 to-indigo-600 text-white px-6 py-3 rounded-full shadow-lg">
                  Powered by Advanced AI
                </div>
              </motion.div> */}
            </div>
          </div>
        </motion.div>
      </div>
    </section>

  {/* Enhanced Market Insights Section */}
  <section className="py-20 bg-white relative overflow-hidden">
  <div className="container mx-auto px-4 md:px-8 xl:px-16">
    <motion.div
      initial={fadeIn.initial}
      whileInView={fadeIn.animate}
      transition={fadeIn.transition}
      className="max-w-7xl mx-auto"
    >
      <div className="text-center mb-16">
        <h2 className="text-4xl font-bold leading-normal mb-6 bg-gradient-to-r from-slate-500 to-slate-800  text-transparent bg-clip-text">Market Insights</h2>
        <p className="text-gray-600 text-xl max-w-3xl mx-auto">
          Understanding our target market and growth potential
        </p>
      </div>

      <div className="flex flex-col lg:grid lg:grid-cols-2 gap-6 md:gap-12">
        {/* Market Size Tiles */}
        <div className="w-full flex justify-center">
        <img 
          src={tam} 
          alt="Market Size Data" 
          className="rounded-xl w-full object-scale-down md:h-[28rem] mt-10 "
        />
      </div>
        


            {/* Market Pyramid */}
            <div className="bg-white rounded-2xl px-8 ">
            
              <div className="relative">
                <svg viewBox="0 0 400 300" className="w-full">
                  {/* Pyramid Sections */}
                  <motion.path
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    d="M200 50 L300 150 L100 150 Z"
                    fill="#A7F3D0"
                    className="hover:opacity-90 transition-opacity cursor-pointer"
                  />
                  
                  <motion.path
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    d="M100 150 L300 150 L350 225 L50 225 Z"
                    fill="#34D399"
                    className="hover:opacity-90 transition-opacity cursor-pointer"
                  />
                  
                  <motion.path
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                    d="M50 225 L350 225 L400 300 L0 300 Z"
                    fill="#059669"
                    className="hover:opacity-90 transition-opacity cursor-pointer"
                  />

                  {/* Labels */}
                 
                  <text x="130" y="145" className="text-xs font-medium" fill="#374151">
                  MEDIUM BUSINESSES
                  </text>
                  <text x="200" y="120" className="text-sm font-bold" fill="#374151" textAnchor="middle">
                    0.55 lakhs
                  </text>

                 
                  <text x="130" y="210" className="text-sm font-medium" fill="#374151">
                  SMALL BUSINESSES
                  </text>
                  <text x="200" y="190" className="text-sm font-bold" fill="#374151" textAnchor="middle">
                    3.3 lakhs
                  </text>

              
                  <text x="120" y="285" className="text-sm font-medium" fill="#374151">
                  MICRO-ENTERPRISES
                  </text>
                  <text x="200" y="260" className="text-sm font-bold" fill="#374151" textAnchor="middle">
                    620.5 lakhs
                  </text>

                 
                </svg>

                
              </div>
              <h3 className="text-2xl text-center font-semibold mt-5 ">Market Segmentation</h3>
            </div>
          </div>

         
        </motion.div>
      </div>
    </section>
 
  {/* Enhanced GTM Section */}
<section className="py-20 bg-gradient-to-b from-white to-gray-50 relative overflow-hidden">
  <div className="container mx-auto px-4 md:px-8 xl:px-16">
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      className="max-w-7xl mx-auto"
    >
      {/* Section Header */}
      <div className="text-center mb-16">
        <h2 className="text-4xl font-bold mb-6">GTM Approach</h2>
        <p className="text-gray-600 text-xl max-w-3xl mx-auto">
          Our comprehensive strategy to reach and serve our target market
        </p>
      </div>

      {/* GTM Cards Grid */}
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
        {[
          {
            title: 'BNI Network',
            icon: 'network',
            description: 'Leverage Business Network International for rapid market penetration and trustworthy partnerships',
            color: 'blue',
            features: ['Trusted Connections', 'Business Referrals', 'Network Growth']
          },
          {
            title: 'Marketing Campaigns',
            icon: 'swords',
            description: 'Strategic digital and traditional marketing campaigns to reach our target audience effectively',
            color: 'green',
            features: ['Social Media', 'Email Marketing', 'Content Strategy']
          },
          {
            title: 'Online/Offline Demo',
            icon: 'demo',
            description: 'Interactive product demonstrations to showcase our solutions capabilities and benefits',
            color: 'purple',
            features: ['Live Demos', 'Virtual Tours', 'Hands-on Experience']
          },
          {
            title: 'Advertisements',
            icon: 'ads',
            description: 'Targeted advertising campaigns across multiple channels to maximize visibility and reach',
            color: 'indigo',
            features: ['Digital Ads', 'Print Media', 'Industry Events']
          }
        ].map((item, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            whileHover={{ y: -5 }}
            className={`bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 border-b-4 ${
              item.color === 'blue' ? 'border-blue-500' :
              item.color === 'green' ? 'border-green-500' :
              item.color === 'purple' ? 'border-purple-500' :
              'border-indigo-500'
            }`}
          >
            {/* Icon */}
            <div className={`w-12 h-12 rounded-lg flex items-center justify-center mb-4 ${
              item.color === 'blue' ? 'bg-blue-100 text-blue-600' :
              item.color === 'green' ? 'bg-green-100 text-green-600' :
              item.color === 'purple' ? 'bg-purple-100 text-purple-600' :
              'bg-indigo-100 text-indigo-600'
            }`}>
              {/* Replace with your preferred icons */}
              {item.icon === 'network' && <Network className="w-6 h-6" />}
              {item.icon === 'swords' && <Swords className="w-6 h-6" />}
              {item.icon === 'demo' && <PlayCircle className="w-6 h-6" />}
              {item.icon === 'ads' && <TrendingUp className="w-6 h-6" />}
            </div>

            {/* Content */}
            <h3 className="text-lg font-semibold mb-2">{item.title}</h3>
            <p className="text-gray-600 mb-4 text-sm">{item.description}</p>

            {/* Features List */}
            <ul className="space-y-2">
              {item.features.map((feature, idx) => (
                <li key={idx} className="flex items-center text-sm text-gray-600">
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ delay: index * 0.1 + idx * 0.1 }}
                    className={`w-1.5 h-1.5 rounded-full mr-2 ${
                      item.color === 'blue' ? 'bg-blue-500' :
                      item.color === 'green' ? 'bg-green-500' :
                      item.color === 'purple' ? 'bg-purple-500' :
                      'bg-indigo-500'
                    }`}
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </motion.div>
        ))}
      </div>

      {/* Bottom Summary */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        className="text-center mt-12 max-w-3xl mx-auto"
      >
        <p className="text-gray-600 text-pretty md:text-center">
          Our GTM strategy ensures comprehensive market coverage and maximum impact through multiple channels and approaches.
        </p>
      </motion.div>
    </motion.div>
  </div>
</section>

{/* Enhanced Timeline Section */}

    <section className="py-20 bg-[#0a123d] text-white">
  <div className="container mx-auto px-4 md:px-8 xl:px-16">
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      className="max-w-7xl mx-auto"
    >
      {/* Section Title */}
      <motion.h2 
        className="text-2xl md:text-5xl font-bold text-left md:text-center mb-20"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        Scaling Bolofy's Reach: Market & Growth Strategy
      </motion.h2>

      {/* Timeline Container */}
      <div className="relative">
        {/* Horizontal Timeline Line - Only visible on md and up */}
        <div className="absolute top-[30px] left-0 w-full h-0.5 bg-indigo-900 hidden md:block">
          <motion.div
            className="h-full bg-indigo-600"
            initial={{ scaleX: 0 }}
            whileInView={{ scaleX: 1 }}
            transition={{ duration: 1.5, ease: "easeOut" }}
            style={{ transformOrigin: "left" }}
          />
        </div>

        {/* Timeline Items */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 relative">
          {/* Vertical Line for Mobile - Only visible on sm and down */}
          <div className="absolute left-[30px] top-0 w-0.5 h-72 bg-indigo-900/30 md:hidden">
            <motion.div
              className="w-full bg-indigo-600"
              initial={{ scaleY: 0 }}
              whileInView={{ scaleY: 1 }}
              transition={{ duration: 1.5, ease: "easeOut" }}
              style={{ transformOrigin: "top" }}
            />
          </div>

          {timelineData.map((item, index) => (
            <motion.div
              key={index}
              className="relative flex md:flex-col md:items-center text-left md:text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              {/* Mobile Layout (Vertical) */}
              <div className="flex items-start md:items-center md:flex-col">
                {/* Number Badge */}
                <motion.div
                  className="w-[60px] h-[60px] bg-indigo-800 rounded-lg flex items-center justify-center text-2xl font-bold relative z-10
                            mb-0 md:mb-6 mr-6 md:mr-0 flex-shrink-0"
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.3 }}
                >
                  {item.number}
                </motion.div>

                {/* Content for Mobile */}
                <div className="pt-3 md:pt-0 md:mt-12 space-y-3 flex-grow md:text-center">
                  <h3 className="text-xl font-bold">{item.age}</h3>
                  <p className="text-gray-300 pr-4 md:pr-0">{item.description}</p>
                </div>
              </div>

              {/* Connecting Elements */}
              {/* Vertical Line from number to content - Desktop */}
              <div className="absolute top-[52px] h-16 w-0.5 bg-indigo-900 hidden md:block" />

              {/* Horizontal Line to Vertical Timeline - Mobile */}
              <div className="absolute left-[-30px] top-[30px] w-[30px] h-0.5 bg-indigo-900/30 md:hidden" />
              
              {/* Right side decorator - Mobile */}
              <div className="absolute left-[30px] top-[30px] w-2 h-2 rounded-full bg-indigo-600 md:hidden" />
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  </div>
</section>

  {/* Enhanced why choose us Section */}
  <section className="py-20 bg-gradient-to-b from-gray-50 to-white relative overflow-hidden">
      {/* Background Decorative Elements */}
      <div className="absolute inset-0">
        <div className="absolute top-20 right-20 w-72 h-72 bg-blue-100 rounded-full mix-blend-multiply filter blur-xl opacity-70" />
        <div className="absolute bottom-20 left-20 w-72 h-72 bg-indigo-100 rounded-full mix-blend-multiply filter blur-xl opacity-70" />
      </div>

      <div className="container mx-auto px-4 md:px-8 xl:px-16 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="max-w-7xl mx-auto"
        >
          {/* Section Header */}
          <div className="text-center mb-16">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="inline-block"
            >
              <h2 className="text-4xl font-bold mb-6">
                Why do our customers pick us over others?
              </h2>
              <div className="h-1 w-40 bg-gradient-to-r from-blue-600 to-indigo-600 rounded-full mx-auto" />
            </motion.div>
          </div>

          {/* Features Grid */}
          <motion.div
            variants={containerWhyVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="grid md:grid-cols-2 lg:grid-cols-3 gap-8"
          >
            {featuresWhy.map((feature, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300"
                whileHover={{ y: -5 }}
              >
                <div className={`
                  w-14 h-14 rounded-xl flex items-center justify-center mb-6
                  ${feature.color === 'blue' && 'bg-blue-100 text-blue-600'}
                  ${feature.color === 'indigo' && 'bg-indigo-100 text-indigo-600'}
                  ${feature.color === 'purple' && 'bg-purple-100 text-purple-600'}
                  ${feature.color === 'green' && 'bg-green-100 text-green-600'}
                  ${feature.color === 'yellow' && 'bg-yellow-100 text-yellow-600'}
                  ${feature.color === 'pink' && 'bg-pink-100 text-pink-600'}
                  ${feature.color === 'orange' && 'bg-orange-100 text-orange-600'}
                `}>
                  <feature.icon className="w-6 h-6" />
                </div>
                <h3 className="text-xl font-bold mb-4">{feature.title}</h3>
                <p className="text-gray-600 leading-relaxed">{feature.description}</p>
              </motion.div>
            ))}
          </motion.div>

          {/* Bottom Tag */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            className="text-center mt-12"
          >
            <p className="text-xl font-semibold text-gradient bg-gradient-to-r from-blue-600 to-indigo-600 text-transparent bg-clip-text">
              We're not just good—we're built for growth
            </p>
          </motion.div>
        </motion.div>
      </div>
    </section>

 {/* Enhanced Testimonial Section */}


  {/* Enhanced Pricing Section */}
  <section id="pricing"className="py-20 bg-gradient-to-b from-gray-50 to-white relative overflow-hidden">
      {/* Background Elements */}
      <motion.div 
        className="absolute inset-0 bg-grid-pattern opacity-5"
        animate={{
          backgroundPosition: ["0px 0px", "100px 100px"],
          opacity: [0.05, 0.08, 0.05]
        }}
        transition={{
          duration: 20,
          repeat: Infinity,
          repeatType: "reverse"
        }}
      />
      
      <div className="container mx-auto px-4 md:px-8 xl:px-16">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="max-w-7xl mx-auto"
        >
          <div className="text-center mb-16">
            <motion.h2 
              className="text-4xl font-bold mb-6"
              initial={{ opacity: 0, y: -20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <span className="bg-gradient-to-r from-blue-600 to-indigo-600 text-transparent bg-clip-text">
                SaaS Pricing Model
              </span>
            </motion.h2>
            <motion.p 
              className="text-gray-600 text-xl max-w-3xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Choose the perfect plan for your business needs
            </motion.p>
          </div>

          <div className="grid md:grid-cols-3 gap-6"> {/* Reduced gap from 8 to 6 */}
  {pricingData.map((plan, index) => (
    <motion.div
      key={index}
      variants={cardPriceVariants}
      whileHover={{ y: -5 }}
      className={`
        relative bg-white rounded-xl p-6 transition-all duration-300 max-w-sm mx-auto
        ${plan.popular ? 'shadow-2xl border-2 border-blue-500' : 'shadow-xl hover:shadow-2xl'}
      `}
    >
      {plan.popular && (
        <motion.div 
          className="absolute -top-3 left-1/2 transform -translate-x-1/2"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.5 }}
        >
          <span className="bg-gradient-to-r -ml-12 from-blue-600 to-indigo-600 text-white px-3 py-1 rounded-full text-xs font-medium">
            Most Popular
          </span>
        </motion.div>
      )}

      <div className="text-center mb-6"> {/* Reduced margin */}
        <h3 className="text-xl font-bold mb-3">{plan.name}</h3> {/* Reduced text size */}
        <div className="text-4xl font-bold mb-3"> {/* Reduced text size and margin */}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600">
            {plan.price}
          </span>
          {plan.price !== '₹0' && (
            <span className="text-gray-400 text-sm font-normal">/month</span>
          )}
        </div>
      </div>

      <ul className="space-y-3 mb-6"> {/* Reduced spacing */}
        {plan.features.map((feature, fIndex) => (
          <motion.li 
            key={fIndex} 
            className="flex items-center space-x-2 text-sm" // Added text-sm for smaller text
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.1 + fIndex * 0.1 }}
          >
            {feature.included ? (
              <div className="p-1 rounded-full bg-green-100">
                <Check className="w-3 h-3 text-green-500" /> {/* Smaller icon */}
              </div>
            ) : (
              <div className="p-1 rounded-full bg-red-100">
                <X className="w-3 h-3 text-red-500" /> {/* Smaller icon */}
              </div>
            )}
            <span className="text-gray-600">{feature.name}</span>
          </motion.li>
        ))}
      </ul>

      <motion.button 
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className={`
          w-full py-2.5 rounded-lg font-semibold text-sm transition-all duration-300
          ${plan.popular 
            ? 'bg-gradient-to-r from-blue-600 to-indigo-600 text-white hover:shadow-lg' 
            : 'bg-gray-100 text-gray-800 hover:bg-gray-200'}
        `}
      >
        {plan.price === '$0' ? 'Get Started Free' : 'Free Trial Included'}
      </motion.button>
    </motion.div>
  ))}
</div>
        </motion.div>
      </div>
    </section>

  {/* Enhanced Team Section */}
  {/* Enhanced Team Section */}
  {/* <section className="py-20 bg-white relative overflow-hidden">
      <div className="container mx-auto px-4 md:px-8 xl:px-16">
        <motion.div
          initial={fadeIn.initial}
          whileInView={fadeIn.animate}
          transition={fadeIn.transition}
          className="max-w-7xl mx-auto"
        >
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-6">Meet Our Team</h2>
            <p className="text-gray-600 text-xl max-w-3xl mx-auto">
              The brilliant minds behind our innovation
            </p>
          </div>

          <div className="grid md:grid-cols-3 lg:grid-cols-3 gap-8">
            {[
              {
                name: 'Santosh',
                role: 'CEO',
                expertise: 'B.E, MBA with 18 years in IT and fundraising',
                image: 'https://i.ibb.co/7kgJVqL/photo-6210930740955299684-x.jpg'
              },
              {
                name: 'Akash',
                role: 'CAIO',
                expertise: 'AI and backend engineering',
                image: 'https://media.licdn.com/dms/image/v2/D4D03AQGJX6sPptrDKA/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1704611353216?e=1738195200&v=beta&t=NC88FnxCj8XMs7qNSeusefoEioydSKggwDCkPDplRko'
              },
              {
                name: 'Rohan',
                role: 'CTO',
                expertise: 'UX, web development',
                image: '/path-to-rohan-image.jpg'
              },
              {
                name: 'Darshit',
                role: 'Full Stack Developer',
                expertise: 'React and frontend development',
                image: 'https://res.cloudinary.com/dkyrtfk1u/image/upload/v1697349373/sli9ffed1vdqegf1srip.jpg'
              },
              {
                name: 'Poojan',
                role: 'Full Stack Developer',
                expertise: 'Node.js development',
                image: '/path-to-pooja-image.jpg'
              },
              {
                name: 'Nimisha',
                role: 'Marketing',
                expertise: 'Market research, onboarding',
                image: '/path-to-nimisha-image.jpg'
              }
            ].map((member, index) => (
              <motion.div
                key={index}
                variants={cardVariants}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="group"
              >
                <div className="relative overflow-hidden rounded-2xl mb-6">
                  <img 
                    src={member.image} 
                    alt={member.name}
                    className="w-full aspect-square object-cover transform group-hover:scale-110 transition-transform duration-500"
                    onError={(e) => {
                      e.target.src = 'https://via.placeholder.com/400x400?text=Team+Member';
                    }}
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-end">
                    <div className="p-6 text-white">
                      <p className="font-medium">{member.expertise}</p>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <h3 className="text-xl font-bold mb-2">{member.name}</h3>
                  <p className="text-blue-600 font-semibold mb-2">{member.role}</p>
                  
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </section> */}


  {/* Modern contact us */}

  <section id="contact-us" className="py-24 bg-gradient-to-br from-violet-950 via-blue-950 to-purple-950 relative overflow-hidden">
      {/* Background Animation */}
      <motion.div 
        className="absolute top-0 left-0 w-[800px] h-[800px] rounded-full opacity-30"
        style={{
          background: 'radial-gradient(circle at center, rgba(217, 70, 239, 0.15), rgba(139, 92, 246, 0.05))'
        }}
        animate={{
          x: [-200, 0, -200],
          y: [-200, 0, -200],
          scale: [1, 1.2, 1],
          rotate: [0, 180, 360],
        }}
        transition={{
          duration: 20,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "linear"
        }}
      />
      <motion.div 
        className="absolute bottom-0 right-0 w-[600px] h-[600px] rounded-full opacity-30"
        style={{
          background: 'radial-gradient(circle at center, rgba(168, 85, 247, 0.15), rgba(192, 132, 252, 0.05))'
        }}
        animate={{
          x: [200, 0, 200],
          y: [200, 0, 200],
          scale: [1.2, 1, 1.2],
          rotate: [360, 180, 0],
        }}
        transition={{
          duration: 20,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "linear"
        }}
      />

      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-2xl mx-auto">
          {/* Section Header */}
          <div className="text-center mb-12">
            <motion.h2 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="text-4xl font-bold text-white mb-4"
            >
             Investors Corner
            </motion.h2>
            <motion.p
  initial={{ opacity: 0, y: 20 }}
  whileInView={{ opacity: 1, y: 0 }}
  transition={{ delay: 0.1 }}
  className="text-purple-100"
>
  We are raising pre-seed at <span className="text-blue-500 font-bold">$1M</span> valuation right now. We welcome investors to participate in this round.
</motion.p>

          </div>

          {/* Contact Form */}
          <motion.form
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            onSubmit={handleSubmit}
            className="bg-white/5 backdrop-blur-md rounded-2xl p-8 space-y-6 border border-white/10"
          >
            {/* Name Field */}
            <div>
              <label htmlFor="name" className={labelClasses}>
                <div className="flex items-center gap-2 mb-2">
                  <User className="w-4 h-4 mb-1" />
                  <span>Your Name</span>
                </div>
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={`${inputClasses} ${
                  errors.name ? 'border-red-500' : 'border-transparent focus:border-blue-500'
                }`}
                placeholder="John Doe"
                disabled={isSubmitting || submitted}
              />
              {errors.name && (
                <motion.p
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="text-red-400 text-sm mt-1"
                >
                  {errors.name}
                </motion.p>
              )}
            </div>

            {/* Email Field */}
            <div>
              <label htmlFor="email" className={labelClasses}>
                <div className="flex items-center gap-2 mb-2">
                  <Mail className="w-4 h-4 mb-1" />
                  <span>Email Address</span>
                </div>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={`${inputClasses} ${
                  errors.email ? 'border-red-500' : 'border-transparent focus:border-blue-500'
                }`}
                placeholder="john@example.com"
                disabled={isSubmitting || submitted}
              />
              {errors.email && (
                <motion.p
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="text-red-400 text-sm mt-1"
                >
                  {errors.email}
                </motion.p>
              )}
            </div>

            {/* Phone Field */}
            <div>
              <label htmlFor="phone" className={labelClasses}>
                <div className="flex items-center gap-2 mb-2">
                  <Phone className="w-4 h-4 mb-1" />
                  <span>Phone Number</span>
                </div>
              </label>
              <input
                type="phone"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className={`${inputClasses} ${
                  errors.phone ? 'border-red-500' : 'border-transparent focus:border-blue-500'
                }`}
                placeholder="+91 93XXXXXXXX"
                disabled={isSubmitting || submitted}
              />
              {errors.phone && (
                <motion.p
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="text-red-400 text-sm mt-1"
                >
                  {errors.phone}
                </motion.p>
              )}
            </div>

            {/* Submit Button */}
            <motion.button
              type="submit"
              disabled={isSubmitting || submitted}
              className="w-full bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-xl py-4 flex items-center justify-center space-x-2 hover:from-blue-700 hover:to-purple-700 transition-all duration-300 disabled:opacity-50"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              {isSubmitting ? (
                <Loader2 className="w-5 h-5 animate-spin" />
              ) : submitted ? (
                <>
                  <Check className="w-5 h-5" />
                  <span>Message Sent</span>
                </>
              ) : (
                <>
                  <Send className="w-5 h-5" />
                  <span>Send Message</span>
                </>
              )}
            </motion.button>
          </motion.form>
        </div>
      </div>

      {/* Toast Notification */}
      <AnimatePresence>
        {showToast.show && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={`fixed bottom-5 right-5 rounded-xl p-4 text-white shadow-lg flex items-center space-x-2 ${
              showToast.type === 'success' ? 'bg-green-600' : 'bg-red-600'
            }`}
          >
            {showToast.type === 'success' ? (
              <Check className="w-5 h-5" />
            ) : (
              <X className="w-5 h-5" />
            )}
            <span>{showToast.message}</span>
          </motion.div>
        )}
      </AnimatePresence>
    </section>


  {/* Modern Footer */}
  <footer className="bg-gray-900 text-white relative overflow-hidden">
      
      

      {/* Main Footer Content */}
      <div className="container mx-auto px-4 md:px-8 xl:px-16 py-16 relative">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-24">
          {/* Company Info */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="space-y-6"
          >
            <motion.img 
              whileHover={{ scale: 1.05 }}
              src={Bolofy} 
              alt="Bolofy Logo" 
              className="h-24 w-40 bg-white/10 backdrop-blur-md p-2 rounded-lg" 
            />
            <p className="text-gray-400 leading-relaxed">
              Transforming business communication with AI-powered solutions for modern enterprises.
            </p>
            <div className="flex space-x-4">
              <motion.a 
                href="https://www.linkedin.com/company/104453905/admin/dashboard/" 
                target='_blank' 
                rel="noreferrer"
                className="p-2 bg-white/5 rounded-full hover:bg-white/10 transition-colors duration-300"
                whileHover={{ scale: 1.1, rotate: 10 }}
                whileTap={{ scale: 0.9 }}
              >
                <Linkedin className="w-5 h-5" />
              </motion.a>
            </div>
          </motion.div>
{/* Quick Links */}
<motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
        
          >
            <h3 className="text-lg font-semibold mb-6 flex items-center gap-2">
              <span className="w-8 h-[2px] bg-blue-500"></span>
              Quick Links
            </h3>
            <ul className="space-y-4">
              {[
                { href: '#contact-us', label: 'Contact Us' },
                { href: '#solution', label: 'Solution' },
                { href: '#pricing', label: 'Pricing' }
              ].map((link, index) => (
                <motion.li
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.3 + index * 0.1 }}
                >
                  <a 
                    href={link.href} 
                    className="text-gray-400 hover:text-white transition-colors duration-300 flex items-center group"
                  >
                    <ArrowRight className="w-4 h-4 mr-2 transform group-hover:translate-x-1 transition-transform duration-300" />
                    {link.label}
                  </a>
                </motion.li>
              ))}
            </ul>
          </motion.div>

{/* Newsletter (Optional) */}
<motion.div
  initial={{ opacity: 0, y: 20 }}
  whileInView={{ opacity: 1, y: 0 }}
  viewport={{ once: true }}
  transition={{ delay: 0.6 }}
  className="space-y-6 md:-ml-24 "
>
  <h3 className="text-lg font-semibold mb-6 flex items-center gap-2">
    <span className="w-8 h-[2px] bg-blue-500"></span>
    Why Choose Bolofy?
  </h3>

  {/* Highlight Cards */}
  <div className="space-y-4">
    <motion.div
      whileHover={{ scale: 1.02 }}
      className="bg-gradient-to-r from-blue-500/10 to-indigo-500/10 p-4 rounded-lg border border-white/10"
    >
      <div className="flex items-start space-x-3">
        <div className="p-2 bg-blue-500/20 rounded-lg">
          <Zap className="w-5 h-5 text-blue-400" />
        </div>
        <div>
          <h4 className="text-white font-medium">Zero Downtime</h4>
          <p className="text-gray-400 text-sm">24/7 uninterrupted service</p>
        </div>
      </div>
    </motion.div>

    <motion.div
      whileHover={{ scale: 1.02 }}
      className="bg-gradient-to-r from-purple-500/10 to-pink-500/10 p-4 rounded-lg border border-white/10"
    >
      <div className="flex items-start space-x-3">
        <div className="p-2 bg-purple-500/20 rounded-lg">
          <Languages className="w-5 h-5 text-purple-400" />
        </div>
        <div>
          <h4 className="text-white font-medium">Multi-Language</h4>
          <p className="text-gray-400 text-sm">Support for 7+ Indian languages</p>
        </div>
      </div>
    </motion.div>

    <motion.div
      whileHover={{ scale: 1.02 }}
      className="bg-gradient-to-r from-green-500/10 to-emerald-500/10 p-4 rounded-lg border border-white/10"
    >
      <div className="flex items-start space-x-3">
        <div className="p-2 bg-green-500/20 rounded-lg">
          <Shield className="w-5 h-5 text-green-400" />
        </div>
        <div>
          <h4 className="text-white font-medium">Secure & Reliable</h4>
          <p className="text-gray-400 text-sm">Enterprise-grade security</p>
        </div>
      </div>
    </motion.div>
  </div>

 
</motion.div>

          

  {/* Contact Info */}
  <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.4 }}
          >
            <h3 className="text-lg font-semibold mb-6 flex items-center gap-2">
              <span className="w-8 h-[2px] bg-blue-500"></span>
              Contact Us
            </h3>
            <ul className="space-y-4">
              <li className="flex items-center space-x-3">
                <div className="p-2 bg-white/5 rounded-full">
                  <Mail className="w-4 h-4" />
                </div>
                <a href="mailto:sp@bolofy.com" className="text-gray-400 hover:text-white transition-colors duration-300">
                  sp@bolofy.com
                </a>
              </li>
              <li className="flex items-center space-x-3">
                <div className="p-2 bg-white/5 rounded-full">
                  <Phone className="w-4 h-4" />
                </div>
                <a href="tel:+919035163809" className="text-gray-400 hover:text-white transition-colors duration-300">
                  +91 90351 63809
                </a>
              </li>
              <li className="flex items-center space-x-3">
                <div className="p-2 bg-white/5 rounded-full">
                  <MapPin className="w-4 h-4" />
                </div>
                <span className="text-gray-400">Bangalore, India</span>
              </li>
            </ul>
          </motion.div>



        

          
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="border-t border-gray-800">
        <div className="container mx-auto px-4 md:px-8 xl:px-16 py-6">
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0"
          >
            <p className="text-gray-400 text-sm">
              © {currentYear} Bolofy. All rights reserved.
            </p>
            
          </motion.div>
        </div>
      </div>
    </footer>

  {/* ScrollToTop Button */}
  {/* <AnimatePresence>
      {isVisible && (
        <motion.button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 z-50 p-3 bg-gradient-to-r from-indigo-600 to-blue-600 text-white rounded-full shadow-lg hover:shadow-xl transition-all duration-300"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0.5, scale: 1 }}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          <svg 
            className="w-6 h-6" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M5 10l7-7m0 0l7 7m-7-7v18" 
            />
          </svg>
        </motion.button>
      )}
    </AnimatePresence> */}

     {/* ScrollToTop Button */}
  <motion.button
    className="fixed bottom-8 right-8 z-50 p-3 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 transition-colors duration-300"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
  >
    <svg 
      className="w-6 h-6" 
      fill="none" 
      stroke="currentColor" 
      viewBox="0 0 24 24"
    >
      <path 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        strokeWidth={2} 
        d="M5 10l7-7m0 0l7 7m-7-7v18" 
      />
    </svg>
  </motion.button>
</div>
);
};

export default LandingPage;