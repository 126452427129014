import LandingPage from './components/LandingPage';
import toast, { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div className="App">
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        gutter={8}
        containerStyle={{
          bottom: 40,
          right: 40,
        }}
        toastOptions={{
          duration: 3000,
          style: {
            background: '#363636',
            color: '#ffffff',
            padding: '16px',
            borderRadius: '10px',
            fontSize: '14px',
          },
          success: {
            style: {
              background: '#10B981',
            },
          },
          error: {
            style: {
              background: '#EF4444',
            },
          },
          loading: {
            style: {
              background: '#3B82F6',
            },
          },
        }}
      />
      <LandingPage />
    </div>
  );
}

export default App;